import * as React from 'react'
import {FC, Fragment} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid,
    TextField,
    TextInput,
    SelectInput,
    CreateButton,
    BulkDeleteButton,
    Pagination,
    BulkUpdateButton,
    TopToolbar,
    SelectField,
    DateField,
    useRecordContext
} from 'react-admin'
//import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import {ProxyImportModal} from 'src/resources/proxy/ImportModal'
import {ModalButton} from 'src/components/Modal/ModalButton'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { ProxyStatusList } from 'src/types/enum/ProxyStatus'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'

/*const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Proxy'
    })
}*/
const ListActions = ({ data } : any) => (
    <TopToolbar>
        {/*filters && cloneElement(filters,
            {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            }
        )*/}
        <CreateButton/>
        <ModalButton label={'Импорт'} icon={<UploadFileIcon/>}  key={'import'} modal={<ProxyImportModal mainRecord={data}/>} />
    </TopToolbar>
)

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <TextInput source="proxy:$startsL" label={'Поиск'} alwaysOn={true} resettable={true} variant={'outlined'}/>
            <SelectInput
                source="status"
                label={'Статус'}
                alwaysOn={true}
                variant={'outlined'}
                choices={[
                    { id: 'blocked', name: 'Заблокировано' },
                    { id: 'active', name: 'Активно' },
                ]}
            />
            <DateRangeFilterInput source={'createdAt:$between'} label="Дата добавления" alwaysOn={true}/>
        </Filter>
    )
}

const ProxyBulkActionButtons = (props: any) => (
    <Fragment>
        <BulkUpdateButton label="Блокировать" data={ {status: 'blocked', countTries: 5} } icon={<PauseIcon/>} />
        <BulkUpdateButton label="Разблокировать" data={ {status: 'active', countTries: 0} } icon={<PlayArrowIcon/>} />
        <BulkDeleteButton  mutationMode={'pessimistic'} confirmTitle={'Удалить выбранные прокси?'}/>
    </Fragment>
)

const ProxyList: FC<ListProps> = (props: any) => {
    const record = useRecordContext()
    return (
        <List
            {...props}
            //exporter={exporter}
            filters={<_Filter/>}
            actions={<ListActions/>}
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}
            title="Прокси"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Список пуст'} description={'Вы можете добавить прокси или импортируйте Excel файл'}
                              buttonText={'Добавить'} importModal={<ProxyImportModal mainRecord={record}/>}/>}
        >
            <Datagrid bulkActionButtons={<ProxyBulkActionButtons/>}>
                <IdShowButtonField source="id" label={'ID'}/>
                <SelectField source="status" label={'Статус'} choices={ProxyStatusList}/>
                <TextField source="proxy" label={'Прокси'}/>
                <TextField source="countTries" label={'Использование'}/>
                <DateField source="createdAt" label={'Создан'} showTime={true}/>
            </Datagrid>
        </List>
    )
}

export default ProxyList
