import * as React from 'react'
import { useGetIdentity, useResourceContext} from 'react-admin'
import {useCanAccess, IfCanAccessProps} from '@glm/ra-rbac'
import {AdminRole} from 'src/types/enum/AdminRole'
interface Props extends IfCanAccessProps{
    aggregator?: boolean
    label?: string
    alwaysOn?: boolean
}
export const IfCanAccess = (props: Props) => {
    const { action, record, children, aggregator } = props
    const {data} = useGetIdentity()
    //const { permissions } = usePermissions()
    const resource = useResourceContext(props)
    const { canAccess, isLoading } = useCanAccess({ action, resource, record })
    const hasRole = [AdminRole.Admin, AdminRole.SuperAdmin].includes(data?.role as AdminRole)
    return (isLoading || !canAccess || !hasRole ? null : <>{children}</>)
}