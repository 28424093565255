import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid,
    TextField,
    TextInput,
    SelectInput,
    CreateButton,
    Pagination,
    TopToolbar,
    DateField,
    SelectField,
    useRecordContext
} from 'react-admin'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import LinkField from 'src/components/fields/LinkField'
import { ImportStatusList } from 'src/types/enum/ImportStatus'
import queryString from 'query-string'
import {Link} from 'react-router-dom'
import get from 'lodash/get'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Import'
    })
}
const ListActions = ({ data } : any) => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
)

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <TextInput source="id" label={'ID'} resettable={true} variant={'outlined'}/>
            <TextInput source="fileName:$contL" label={'Файл'} alwaysOn variant={'outlined'}/>
            <DateRangeFilterInput source={'createdAt:$between'} label="Дата создания" alwaysOn/>
            <SelectInput source="status" label={'Статус'} variant={'outlined'} choices={ImportStatusList} />
        </Filter>
    )
}

const LinkToRelatedRequests = (props: Props) => {
    const record = useRecordContext(props)
    //console.log('record', record)
    return (
        <Link
            to={{
                pathname: '/request',
                search: queryString.stringify({
                    page: 1,
                    perPage: 25,
                    sort: 'id',
                    order: 'DESC',
                    filter: JSON.stringify({ requestImportId: record?.id }),
                }),
            }}
        >
            {get(record, props.source ?? '') || '0'}
        </Link>)
}

const ImportList: FC<ListProps> = props => {
    const record = useRecordContext(props)
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            actions={<ListActions/>}
            pagination={<Pagination/>}
            title="Импорты"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Список пуст'} description={'Вы можете добавить'}
                              buttonText={'Добавить'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source='id' type={'show'} />
                <TextField source="fileName" label={'Файл'}/>
                <SelectField
                    source="status"
                    label={'Статус'}
                    choices={ImportStatusList}
                />
                <TextField source="totalRows" label={'Всего'}/>
                <LinkToRelatedRequests source="importedRows" label={'Импортировано'}/>
                <LinkField link={'/import/$id/show/errors'} source="errorRows" label={'Ошибок'}/>
                <DateField source="createdAt" label={'Создан'} showTime={true}/>
            </Datagrid>
        </List>
    )
}

export default ImportList
