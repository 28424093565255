import * as materialColors from '@mui/material/colors'
import { defaultTheme } from 'react-admin'
export const colors = {
  primary: materialColors.blue[800],
  secondary: materialColors.blue[700],
}

export const chartColors = [
  materialColors.blue[400],
  materialColors.red[400],
  materialColors.green[400],
  materialColors.purple[400],
  materialColors.indigo[400],
  materialColors.amber[400],
  materialColors.pink[400],
  materialColors.orange[400],
  materialColors.blueGrey[400],
  materialColors.brown[400],
  materialColors.cyan[400],
  materialColors.deepOrange[400],
  materialColors.deepPurple[400],
  materialColors.grey[400],
  materialColors.lightBlue[400],
  materialColors.lightGreen[400],
  materialColors.yellow[400],
  materialColors.teal[400],
]

export const theme = {
  ...defaultTheme,
  overrides:{
    sidebar: {
      width: 340,
      closedWidth: 55,
    },
    RaSidebar: {
      fixed: {
        zIndex: 1200,
      },
    },
  },
 palette: {
    ...defaultTheme.palette,
   primary: {
     main: '#117492', // Not far from red
   },
   secondary: {
     main: '#117492', // Not far from green
   },
 },

  components: {
    ...defaultTheme.components,
    RaLayout: {
      styleOverrides: {
        root: {
          backgroundColor: '#F0ECFB',
          '& .RaLayout-content': {
            backgroundColor: '#F0ECFB',
          },
        }
      },
    },/*
    RaAutocompleteInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            paddingTop: '0px',
            paddingBottom: '0px'
          },
          '&  .MuiInputLabel-root': {
            transform: 'translate(14px, 9px) scale(1)'
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)'
          }

        }
      },
    },*/
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
    },

    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#117492'
      }
    },

    MuiTab: {
      root: {
        '&:hover': {
          color: '#117492'
        }
      },
      selected: {
        color: '#117492',
        '&:hover': {
          color: '#117492',
        }
      }
    },
  }
}
