import * as React from 'react'
import {FC} from 'react'
import {
  EditProps,
} from 'react-admin'
import AdminForm from 'src/resources/admin/AdminForm'
import {CustomEdit} from 'src/components/CustomEdit'

interface TitleProps {
  record?: any;
}

const AdminEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} mutationMode="pessimistic">
    <AdminForm/>
    </CustomEdit>
  )
}
export default AdminEdit