export enum RequestStatus {
    Created = 'created',
    InQueue = 'in_queue',
    InProgress = 'in_progress',
    Error = 'error',
    //Sent = 'sent',
    Completed = 'completed',
    Canceled = 'canceled',
}

export const RequestStatusList = [
  {id: RequestStatus.Created, name: 'Создан'},
  {id: RequestStatus.InQueue, name: 'В очереди'},
  {id: RequestStatus.InProgress, name: 'В процессе'},
  {id: RequestStatus.Error, name: 'Ошибка'},
  {id: RequestStatus.Completed, name: 'Завершен'},
  {id: RequestStatus.Canceled, name: 'Остановлен'},
]