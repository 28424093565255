import React from 'react'
import {Admin} from '@glm/ra-enterprise'
//import {Resource} from 'src/components/Resource'
import {AuthProvider} from 'src/common/providers/AuthProvider'
import dataProvider from 'src/common/providers/dataProvider'
import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import {theme} from 'src/style'
import Request from 'src/resources/request'
import Proxy from 'src/resources/proxy'
import Import from 'src/resources/import'
import AdminUser from 'src/resources/admin'
import {AppLayout} from 'src/components/layout'
import {RaRecord} from 'react-admin'
import {Resource} from 'src/components/Resource'


const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

const App = () => {
    const orderRecordRepresentation = (record: RaRecord) => `№ ${record.number ?? record.id}`
    return (
        
        <Admin
            lightTheme={theme as any}
            i18nProvider={i18nProvider}
            dataProvider={dataProvider}
            authProvider={AuthProvider}
            layout={AppLayout}
            requireAuth
        >
            <Resource key={'request'} name={'request'} {...Request} options={{label: 'Запросы'}}
                        recordRepresentation={(record: RaRecord) => `${record.id} – ИНН ${record.inn}`}/>
            <Resource key={'import-error'} name="import-error"/>,
            <Resource key={'import'} name={'import'} {...Import} options={{label: 'Импорты'}}
                        recordRepresentation={(record: RaRecord) => `${record.id} – ${record.fileName}`}/>
            <Resource key={'proxy-import'} name={'proxy-import'}/>
            <Resource key={'proxy'} name={'proxy'} {...Proxy} options={{label: 'Прокси'}}
                        recordRepresentation={(record: RaRecord) => `${record.id} – ${record.proxy}`}/>
            <Resource key={'user'} name={'user'} {...AdminUser} options={{label: 'Пользователи'}}
                        recordRepresentation={(record) => `${record.name} – ${record.login}`}/>

        </Admin>
        
    )
}

export default App
