import ListIcon from '@mui/icons-material/List'
import ImportList from 'src/resources/import/ImportList'
import ImportCreate from 'src/resources/import/ImportCreate'
import ImportShow from './ImportShow'

export default {
  create: ImportCreate,
  show: ImportShow,
  list: ImportList,
  icon: ListIcon,
}