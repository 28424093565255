//import { DateRangePicker, DateRange } from '@matharumanpreet00/react-daterange-picker'
import {DateRangePicker, DateRange} from 'mui-daterange-picker'
import React, {ComponentProps, ReactElement, useCallback} from 'react'
import {useInput} from 'ra-core'
import {format as dateFormat} from 'date-fns'
import {TextInput, useTranslate} from 'react-admin'
import styles from './index.module.scss'
import {InputHelperText} from 'ra-ui-materialui'

export interface Props {
    label?: string | ReactElement;
    source: string;
    fullWidth?: boolean;
    helperText?: ComponentProps<typeof InputHelperText>['helperText'];
    record?: Record<any, any>;
    resource?: string;
    variant?: string;
    useUTC?: boolean;
    [key: string]: any;
}

const DateRangeFilterInput = (props: Props) => {
    const {...fieldProps} = props

    const {
        options,
        label,
        source,
        fullWidth,
        format,
        parse,
        resource,
        className,
        providerOptions,
        validate,
        helperText,
        ...rest
    } = fieldProps
    const [open, setOpen] = React.useState(false)
    const translate = useTranslate()
    const toggle = () => setOpen(!open)

    const {
        id,
        isRequired,
        field: {value, onChange: onChangeInput},
        fieldState: {isTouched: touched, error},
    } = useInput({source, ...rest})


    const handleChange = useCallback((value: any) => {
        //console.log('HandleChange', `${value.startDate.toISOString()},${value.endDate.toISOString()}`,value.startDate, value.endDate, dateFormat(value.startDate, 'dd.MM.yy'), dateFormat(value.endDate, 'dd.MM.yy'))
        //console.log('DateProps', props)
        // eslint-disable-next-line quotes
        onChangeInput(props.useUTC ? `${value.startDate.toISOString()},${value.endDate.toISOString()}` : `${dateFormat(value.startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxx")},${dateFormat(value.endDate, "yyyy-MM-dd'T23:59:59.999'xx")}`)

    }, [])

    const formatDate = (date: string) => {
        if (!date) {
            return ''
        }
        //console.log('formatDate', date)
        try {
            return dateFormat(new Date(date), 'dd.MM.yy')
        } catch (e) {
            return ''
        }
    }
    const formatValue = (value: any) => {
        if (!value){
            return ''
        }
        const valueParts = value?.split(',')
        return `${formatDate(valueParts[0])} - ${formatDate(valueParts[1])}`
    }
    const getInitialDateRange = (): DateRange => {
        if (!value || !value.between) {
            return {
                startDate: new Date(),
                endDate: new Date() //Math.floor(Date.now() / 86400000) * 86400000 + 86400000 - 1)
            }
        }
        const valueParts = value?.split(',')
        return {
            startDate: new Date(valueParts[0]),
            endDate: new Date(valueParts[1]) //(Date.parse(valueParts[1]) + 86400000 - 1)
        }
    }

    //console.log('Value11', 'value', value)
    return (
        <>

            <TextInput
                source={source}
                onChange={(value) =>{
                    if(!value){
                        onChangeInput('')
                    }
                }}
                resettable
                label={label}
                helperText={false}
                variant={'outlined'}
                inputProps={{
                    onClick: () => toggle()
                }}
                format={formatValue}
            />
            <div className={styles.wrapper}>
                {<DateRangePicker
                    initialDateRange={getInitialDateRange()}
                    open={open}
                    wrapperClassName={styles.wrapper}
                    toggle={toggle}
                    onChange={(range: DateRange) => handleChange(range)}
                />}
            </div>

        </>
    )
}


export default DateRangeFilterInput
