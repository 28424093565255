import * as React from 'react'
import {
  TextInput, SelectInput, required, SimpleForm, BooleanInput,
} from 'react-admin'
//import {ChangeEventHandler} from 'react'
import {AdminRoleList} from 'src/types/enum/AdminRole'
import {IfCanAccess} from 'src/components/permissions/IfCanAccess'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const PasswordPolicy = props => (
  <Box m={2} top={0} marginTop={0}>
    <Typography variant={'body2'} color={'error'} >Требования к паролю:</Typography>
    <Typography variant={'body2'} color={'error'} > - минимальная длина 6 символов;</Typography>
    <Typography variant={'body2'} color={'error'} > - содержит строчные и прописные символы латинского алфавита;</Typography>
    <Typography variant={'body2'} color={'error'} > - содержит минимум одну цифру;</Typography>
    <Typography variant={'body2'} color={'error'} >{' - содержит минимум один спец. символ: !"#$%&\'()*+,-./:;<=>?@[]^_`{|}~;'}</Typography>
    <Typography variant={'body2'} color={'error'} > - не совпадает с ранее установленными.</Typography>
  </Box>
)

const Form = (props: any) => {
  //const { permissions } = usePermissions()
  //const form = useFormContext()
  
  return (<>
    <SelectInput
        source="role"
        label={'Роль'}
        choices={AdminRoleList}
        fullWidth
        variant={'outlined'}
        validate={required()}
    />
    <TextInput
        source="name"
        label={'Имя'}
        fullWidth
        variant={'outlined'}
    />
    <TextInput
        source="login"
        label={'Логин'}
        fullWidth
        variant={'outlined'}
        validate={required()}
    />
    <TextInput
        source="setPassword"
        label={'Пароль'}
        variant={'outlined'}
        fullWidth
    />
    <PasswordPolicy />
    <IfCanAccess aggregator action={'edit'}>
      <BooleanInput
          source="isDisable"
          label={'Блокировать учетную запись'}
          variant={'outlined'}
          fullWidth
      />
    </IfCanAccess>
    </>)
}
const AdminForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{status: 'active', countTries: 0}}>
      <Form/>
    </SimpleForm>
  )
}
export default AdminForm
