import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps
} from 'react-admin'
import AdminForm from 'src/resources/admin/AdminForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новый пользователь</span>

const AdminCreate: FC<EditProps> = props => {
  return (
    <Create {...props} title={<Title/>}>
      <AdminForm/>
    </Create>
  )
}

export default AdminCreate