import * as React from 'react'
import {Children, isValidElement, cloneElement} from 'react'
import classnames from 'classnames'
import {
    Labeled,
} from 'react-admin'

import Grid from '@mui/material/Grid'

export const SanitizedGrid = ({ ...props }) => {
    //console.log('props.children', props.children)
    const newProps = {
        record: (props as any).record,
        resource: (props as any).resource,
        basePath: (props as any).basePath,
    }
    return (
        <Grid item={props.item} md={props.md} container={props.container}>
            {Children.map(props.children, field =>
                field && isValidElement(field) ? (
                    ['Box', 'SanitizedGrid'].indexOf((field.type as any).name) > -1 ? cloneElement(field, { ...props, ...(field.props as any) }) :
                        <div
                            key={(field.props as any).source}
                            className={classnames(
                                `ra-field ra-field-${(field.props as any).source}`,
                                (field.props as any).className
                            )}
                        >
                            {(field.props as any).label ? (
                                <Labeled
                                    resource={props.resource}
                                    label={(field.props as any).label}
                                    source={(field.props as any).source}
                                >
                                    {field}
                                </Labeled>
                            ) : typeof field.type === 'string' ? (
                                field
                            ) : (
                                cloneElement(field, newProps)
                            )}
                        </div>
                ) : null
            )}
        </Grid>
    )
}