import * as React from 'react'
import {FC} from 'react'
import {
  EditProps,
} from 'react-admin'
import ProxyForm from 'src/resources/proxy/ProxyForm'
import {CustomEdit} from 'src/components/CustomEdit'

const ProxyEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" >
      <ProxyForm/>
    </CustomEdit>
  )
}
export default ProxyEdit