import React, {FC, ReactElement, Fragment, useState, MouseEventHandler} from 'react'
import CreateIcon from '@mui/icons-material/Create'
import AddIcon from '@mui/icons-material/Add'
import {
  Button
} from 'react-admin'
import IconButton from '@mui/material/IconButton'

interface Props {
  children?: ReactElement;
  label?: string;
  type?: 'iconButton' | 'button'
  icon?: ReactElement,
  modal?: ReactElement
}

export const ModalButton: FC<Props> = (props) => {
  const {
    label,
    icon,
    children,
    modal,
    type = 'button'
  } = props
  const [showDialog, setShowDialog] = useState(false)

  const handleClick: MouseEventHandler = (e) => {
    setShowDialog(true)
    e.stopPropagation()
    e.preventDefault()
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  return (
    <>
      {type === 'button' && <Button type={'button'} size={'small'}  onClick={handleClick} label={label}>
        {icon ?? children}
      </Button>}
      {type === 'iconButton' && <IconButton type={'button'}  onClick={handleClick} >
        {icon ?? children}
      </IconButton>}
      {showDialog && modal && React.cloneElement(modal, {
        ...props,
        onClose: handleCloseClick,
        isShown: showDialog,
      })}
    </>
  )
}



export const ModalEditButton = (props: Props) => <ModalButton label={'ra.action.edit'} icon={<CreateIcon/>}  {...props}/>
export const ModalCreateButton = (props: Props) => <ModalButton label={'ra.action.create'} icon={<AddIcon/>}  {...props}/>
