import * as React from 'react'
import {RecordContextProvider, useDataProvider, useRefresh, FileInput, FileField} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import {FieldValues} from 'react-hook-form'
interface Props{
    isShown?: boolean
    id?: number
    mainRecord: Record<any, any>
    onClose?: () => void
}
export const ProxyImportModal = (props: Props) => {
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const handleSubmit = async (data: FieldValues) => {
        //console.log('HandleSubmit', data)
        const res = await dataProvider.create('proxy-import', {data: {...data}})
            refresh()

        if(props.onClose){
            props.onClose()
        }
        return true
    }

    return <RecordContextProvider value={{}}>
        <ModalForm
        isShown
        defaultValues={{}}
        fullWidth={true}
        maxWidth={'lg'}
        resource={'proxy-import'}
        title={'Импорт прокси'}
        onClose={props.onClose}
        save={handleSubmit}
    >
        <FileInput source="file" label={'Файл импорта'} helperText={'Поддерживаемый формат xlsx и csv'} accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','.csv']} multiple={false}>
            <FileField source="src" title="title" />
        </FileInput>
    </ModalForm>
    </RecordContextProvider>
}
