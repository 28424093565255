import * as React from 'react'
import {Fragment} from 'react'
import {RecordContextProvider, useDataProvider, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import {FieldValues} from 'react-hook-form'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import downloadURL from 'src/components/downloadUrl'
import Download from '@mui/icons-material/Download'
//const querystring = require('querystring')
import queryString from 'query-string'
interface Props{
    isShown?: boolean
    id?: number
    mainRecord: Record<any, any>
    resource: string
    total?: number
    selectedIds?: any[]
    sort?: any
    filter?: any
    title?: string
    saveButtonLabel?: string
    saveButtonIcon?: any
    onClose?: () => void
}
const getNameFromFilter = (filters: any) => {
    const names: string[] = []
    const getNameFromFilter = (name: string, op: string | undefined, value: any) => {
        if (op === undefined) op = name.split(':')?.at(1)
        if (!op || op === 'eq' || op === '$eq') op = '='
        else op = '$'+op.replace('$', '')+'$'
        if (
          name.startsWith('requestImportId') ||
          name.startsWith('request.requestImportId')
        ) {
          return `import${op}${value}`
        } else {
          return `${name}${op}${value}`
        }
    }
    if (Array.isArray(filters)) {
        for (const filter of filters) {
          const parts = filter.split('||')
          names.push(
            getNameFromFilter(parts[0], parts.length === 2 ? 'eq' : parts[1], parts[parts.length - 1])
          )
        }
    } else {
        for (const [name, val] of Object.entries(filters)) {
          names.push(getNameFromFilter(name, undefined, val))
        }
    }
    return names.join('_')
}
export const ExportRequestModal = (props: Props) => {
    let {total, selectedIds, sort, filter} = props
    if (selectedIds) total = selectedIds.length
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    console.log(`${total} props `, props)
    const handleSubmit = async (data: FieldValues) => {
        const qdata = {
            sort: sort ? [`${sort.field},${sort.order}`] : [],
            filter: [],
            //page: 1, limit: 100000
        }
        if (selectedIds && selectedIds.length > 0)
            qdata.filter.push(`id||$in||${selectedIds.join(',')}`)
        else for (const filt of Object.entries(filter))
            qdata.filter.push(filt[0].replace(':', '||')+'||'+filt[1])
        console.log(`qdata ${JSON.stringify(filter)}`, qdata)
            //const xlsdata = await dataProvider.create('request/export', qdata)
        //.then(({ data }) => {
            const curDate = format(new Date(), 'dd.MM.yyyy_HH:mm:ss', { locale: ru })
        if (props.resource.includes('export')) {
            const href=`${process.env.REACT_APP_API_URL || ''}/api/${props.resource}?${queryString.stringify(qdata, {arrayFormat: 'bracket', })}`
            const fileName = selectedIds ? `${total}_requests_${curDate}.zip` : `${total}_filter_${getNameFromFilter(qdata.filter)}_${curDate}.zip`
            console.log(`save file ${fileName}`, href)
            //downloadZip(data, fileName) 
            downloadURL(href, fileName)
        } else {
            const udata = await dataProvider.create(props.resource, { data: qdata })
        }
        refresh()
        //})
        if(props.onClose){
            props.onClose()
        }
        return true
    }

    return <RecordContextProvider value={{}}>
        <ModalForm
        isShown
        defaultValues={{}}
        fullWidth={true}
        maxWidth={'lg'}
        resource={props.resource}
        title={props.title || 'Экспорт'}
        saveButtonLabel={props.saveButtonLabel || 'Скачать'}
        saveButtonIcon={props.saveButtonIcon || <Download/>}
        onClose={props.onClose}
        save={handleSubmit}
    >
        <Fragment>
            <Box  p={2}><Typography>Количество {props.resource.includes('export') ? 'экспортируемых строк' : 'переотправляемых запросов'}: <b>{selectedIds && selectedIds.length > 0 ? selectedIds.length : total}</b></Typography>
            </Box>
        </Fragment>

        {total > 10000 &&  <Fragment> <Box m={2} /><Box bgcolor="warning.main" color="warning.contrastText" p={2}><Typography>Внимание! Количество {props.resource.includes('export') ? 'экспортируемых строк' : 'переотправляемых запросов'} больше 10000</Typography></Box></Fragment>}
    </ModalForm>
    </RecordContextProvider>
}
