export enum AdminRole {
  SuperAdmin = 'super_admin',
  Admin = 'admin',
  //Manager = 'manager',
  Operator = 'operator',
}

export const AdminRoleList = [
  {id: AdminRole.SuperAdmin, name: 'Супер администратор'},
  {id: AdminRole.Admin, name: 'Администратор'},
  {id: AdminRole.Operator, name: 'Оператор'},
]

