import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CreateButton } from 'react-admin'
import { ModalButton } from '../Modal/ModalButton'
import UploadFileIcon from '@mui/icons-material/UploadFile'
interface Props{
    title?: string
    basePath?: string,
    description?: string
    buttonText?: string
    hasCreate?: boolean
    importModal?: any
}
const EmptyList = ({ basePath, title = 'Список пуст', hasCreate = true, description, buttonText, importModal }: Props) => (
  <Box textAlign="center" m={3} flex={1}>
    <Typography variant="h4" paragraph>
      {title}
    </Typography>
    <Typography variant="body1">
      {description}
    </Typography>
      {hasCreate && <CreateButton label={buttonText} resource={basePath} />}
      {importModal !== undefined ? <ModalButton label={'Импорт'} icon={<UploadFileIcon/>}  key={'import'} modal={importModal} /> : null }
  </Box>
)

export default EmptyList
