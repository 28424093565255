/* eslint-disable react/jsx-key */
import * as React from 'react'
import {FC} from 'react'
import {
    List,
    ListProps,
    TextInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    Pagination,
    TopToolbar,
    SelectField,
    TextField,
    DateField,
    NumberField,
    FilterButton,
    SelectColumnsButton,
    DatagridConfigurable,
    BooleanField,
    useListContext,
    FunctionField,
    ReferenceField,
    BulkUpdateButton,
} from 'react-admin'
import EmptyList from 'src/components/list/EmptyList'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import {ModalButton} from 'src/components/Modal/ModalButton'
import DownloadFileIcon from '@mui/icons-material/FileDownload'
import RepeatIcon from '@mui/icons-material/Repeat'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { RequestStatusList } from 'src/types/enum/RequestStatus'
import { ExportRequestModal } from './ExportModal'
import RefreshIcon from '@mui/icons-material/Refresh'

/*const exporter: Exporter = (
    data: any,
    fetchRelatedRecords,
    dataProvider: DataProvider,
    resource?: string
) => {    //const dataProvider1 = useDataProvider()
    //const { sort, total, filterValues, selectedIds } = useListContext()
    const total = data.length
    const filterValues={}
    const selectedIds=data.map((e) => e.id)
    console.log(`export ${total}`, data)
    //const selectedIds = ids.reduce(r => ','+r.id, '').substring(1)
        //fetchRelatedRecords(records, 'post_id', 'request/export').then((xlsdata) =>
        const xlsdata = dataProvider.create('request/export', {data: {
            //sort,
            filter: selectedIds && selectedIds.length > 0 ? [`id||$in||${selectedIds.join(',')}`]  : filterValues,
            }
        }).then((data) => {
        //const link = await dataProvider.getList('request/export/sign', {
        //           sort,
        //            filter: selectedIds && selectedIds.length > 0 ? [`id||$in||${selectedIds.join(',')}`]  : filter,
        //            page: 1, perPage: 100000 }
        //          )
            const curDate = format(new Date(), 'dd.MM.yyyy_HH:mm:ss', { locale: ru })
            const fileName = selectedIds ? `${total}_requests_${curDate}.xlsx` : `${total}_filter_getNameFromFilter(filter)_${curDate}.xlsx`
            console.log(`save file ${fileName}`, xlsdata)
            downloadZip(data, fileName)
        })
}*/

const requestFilters = [
    <TextInput source="id" label="ID" resettable/>,
    <ReferenceInput label="Импорт" alwaysOn emptyText="Не применять" allowEmpty source="requestImportId"
            reference="import" fullWidth>
        <SelectInput optionText={(record) => `${record.id} от ${format(new Date(record.createdAt), 'dd MMMM yyyy HH:mm', { locale: ru })} - ${record.fileName}`}/>
    </ReferenceInput>,
    <TextInput source="inn:$contL" label="ИНН" alwaysOn/>,
    <DateRangeFilterInput source="date:$between" label="Отчетная дата" alwaysOn/>,
    <SelectInput alwaysOn emptyText="Не определено" source="resultStatus" label="Плательщик НПД" choices={[
      { id: 'true', name: 'Да' },
      { id: 'false', name: 'Нет' },
    ]}/>,
    <DateRangeFilterInput source="createdAt:$between" label="Дата создания"/>,
    <SelectInput source="status" label="Статус" choices={RequestStatusList} alwaysOn />,
    <NumberInput source="resCode" label="Код ошибки"/>,
    <TextInput source="error:$contL" label="Ошибка"/>,
]

const ListActions = () => {
    const { data, sort, total, filterValues } = useListContext()
    return (
    <TopToolbar>
        <FilterButton filters={requestFilters}/>
        <SelectColumnsButton />
        <ModalButton label={'Экспорт'} icon={<DownloadFileIcon/>}  key={'request/export'} modal={
            <ExportRequestModal mainRecord={data}
                sort={sort}
                filter={filterValues}
                total={total}
                resource={'request/export'}
            />}
        />
        <ModalButton label={'Переотправить'} icon={<RepeatIcon/>}  key={'request/resend'} modal={
            <ExportRequestModal mainRecord={data}
                sort={sort}
                filter={filterValues}
                total={total}
                resource={'request/resend'}
                title={'Переотправить по фильтру'}
                saveButtonLabel={'Переотправить'}
                saveButtonIcon={<RepeatIcon/>}
            />}
        />
    </TopToolbar>
    )
}

const RequestBulkActionButtons = (props: any) => {
    const { data, sort, total, filterValues } = useListContext()
    return (<>
        <BulkUpdateButton label="Переотправить" data={ {status: 'created'} } icon={<RefreshIcon/>} />
        <ModalButton label={'Экспорт'} icon={<DownloadFileIcon />} key={'request/export'} modal={<ExportRequestModal mainRecord={data} resource={'request/export'} { ...{selectedIds: props.selectedIds,  sort, total, filterValues }} />} />
    </>)
}

/*const LinkToRelatedImport = ({ record }: any) => (
    <Button
    color="primary"
    component={Link}
    to={{
        pathname: '/import',
        search: JSON.stringify({
        page: 1,
        perPage: 25,
        sort: 'id',
        order: 'DESC',
        filter: JSON.stringify({ id: record.requestImportId }),
        }),
    }}
    >{'Импорт'}</Button>
)*/

  
const RequestList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={false}
            filters={requestFilters}
            actions={<ListActions/>}
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}
            title="Запросы"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Список пуст'} description={'Вы можете добавить'}
                              buttonText={'Добавить'}/>}
        >
            <DatagridConfigurable bulkActionButtons={<RequestBulkActionButtons/>}>
                <IdShowButtonField source="id" type={'show'}/>
                <TextField source="customId" label={'ID внутренний'}/>
                <SelectField
                    source="status"
                    label={'Статус'}
                    choices={RequestStatusList}
                />
                <TextField source="inn" label={'ИНН'}/>
                <DateField source={'date'} label={'Отчетная дата'}/>
                <ReferenceField label="Импорт" source="requestImportId" reference="import" link={'show'}>
                    <FunctionField source="id" render={(imp) => `#${imp.id} (${imp.fileName})`}/>
                </ReferenceField>
                <BooleanField source={'resultStatus'} label={'Плательщик НПД'}/>
                <NumberField source='resCode' label={'Код ошибки'}/>
                <TextField source='error' label={'Ошибка'}/>
                <DateField source={'createdAt'} label={'Создан'} showTime/>
            </DatagridConfigurable>
        </List>
    )
}

export default RequestList
