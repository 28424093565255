import React from 'react'
import { MultiLevelMenu } from '@glm/ra-navigation'
import { ChartDonutVariant, Publish, Ethernet, AccountTie } from 'mdi-material-ui'
import {IfCanAccess} from 'src/components/permissions/IfCanAccess'

export const MyMenu = () => (
    <MultiLevelMenu>
        <MultiLevelMenu.Item name="request" to="/request" label="Запросы" icon={<ChartDonutVariant />} />
        <MultiLevelMenu.Item name="import" to="/import" label="Импорты" icon={<Publish />} />

        <IfCanAccess resource={'proxy'} action={'show'}>
            <MultiLevelMenu.Item name="promo" to="/proxy" label="Прокси" icon={<Ethernet />}  />
        </IfCanAccess>

        <IfCanAccess resource={'user'} action={'show'}>
            <MultiLevelMenu.Item name="user" to="/user" label="Пользователи" icon={<AccountTie />}  />
        </IfCanAccess>

    </MultiLevelMenu>
)