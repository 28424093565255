import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  SelectField,
  DateField,
  NumberField,
  ReferenceField,
  useRecordContext,
  ListProps,
  FunctionField
} from 'react-admin'
//import Grid from '@mui/material/Grid'
import { RequestStatusList } from 'src/types/enum/RequestStatus'
import { SanitizedGrid } from 'src/components/SanitizedGrid'

const ErrorGrid = () => {
  const record = useRecordContext()
  //console.log('record', record)
  if (!record?.resCode && !record?.error)
    return null
  return (
    <SanitizedGrid container={false} item md={4}>
      <NumberField source='resCode' label={'Код ошибки'}/>
      <TextField source='error' label={'Ошибка'}/>
      <NumberField source='countTries' label={'Количество попыток'}/>
    </SanitizedGrid>
  )
}

const RequestShow: FC<ListProps> = props => {
  return (
    <Show emptyWhileLoading>
      <SimpleShowLayout>
        <SanitizedGrid container spacing={4}>
          <SanitizedGrid container={false} item md={4}>
            <ReferenceField label="Импорт" source="requestImportId" reference="import" link={'show'}>
              <TextField source="id"/>
            </ReferenceField>
            <TextField source="customId" label={'ID внутренний'}/>
            <TextField source="inn" label={'ИНН'}/>
            <DateField source={'date'} label={'Отчетная дата'}/>
          </SanitizedGrid>
          <SanitizedGrid container={false} item md={4}>
            <DateField source={'createdAt'} label={'Создан'} showTime/>
            <DateField source={'completedAt'} label={'Проверен'} showTime/>
            <SelectField source="status" label={'Статус'} choices={RequestStatusList}/>
            <FunctionField render={(rec: any) => (rec.resultStatus ? 'Да' : (rec.resultStatus === false ? 'Нет' : 'Не определено'))} label={'Плательщик НПД'}/>
            <TextField source='resultMessage' label={'Результат'}/>
          </SanitizedGrid>
          <ErrorGrid/>
        </SanitizedGrid>
      </SimpleShowLayout>
    </Show>
  )
}
export default RequestShow
