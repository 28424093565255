import * as React from 'react'
import {
  SimpleForm,
  required,
  FileInput,
  FileField,
} from 'react-admin'

const Form = (props: any) => {
  return (<>
    <FileInput source="file" multiple={false} label="Файл импорта" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" placeholder={<p>Перетащите файл сюда или кликните, чтобы выбрать файл.</p>} validate={required()}>
      <FileField source="src" title="title" />
    </FileInput>
  </>)
}
const ImportForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{status: 'active', countTries: 0}}>
      <Form/>
    </SimpleForm>
  )
}
export default ImportForm
