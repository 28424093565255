import {AdminRole} from 'src/types/enum/AdminRole'

const actionAll = (resources: string[]) => {
    return resources.map(i => ({action: '*', resource: i}))
}
export const ROLES = {
    [AdminRole.SuperAdmin]: [{action: '*', resource: '*'}],
    [AdminRole.Admin]: [
        {action: '*', resource: 'import'},
        {action: 'read', resource: 'import-error'},
        {action: '*', resource: 'request'},
        {action: '*', resource: 'user'},
    ],
    //[AdminRole.Operator]: actionAll(['import','request']),
    [AdminRole.Operator]: [
        {action: '*', resource: 'import'},
        {action: 'read', resource: 'import-error'},
        {action: '*', resource: 'request'}
    ],
    
}