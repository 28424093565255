import * as React from 'react'
import {
  Show,
  TabbedShowLayout,
  TextField,
  Pagination,
  SelectField,
  Tab,
  DateField,
  ReferenceManyField,
  FileField,
  useRecordContext,
  Labeled,
  Datagrid,
  TopToolbar,
  DeleteButton,
  useGetIdentity,
} from 'react-admin'
//import RelatedList from 'src/components/list/RelatedList'
import { ImportStatusList } from 'src/types/enum/ImportStatus'

const ImportFileField = (props: any) => {
  const record = useRecordContext()
  record.file = record.file?.url ? record.file : {name: record.fileName, url: `${process.env.REACT_APP_API_URL || ''}/api/import/${record.id}/${record.file}`}
  return (
    <Labeled
      resource={props.resource}
      label={'Файл'}
      source={props.source}>
      <FileField source="file.url" title="file.name" />
    </Labeled>
  )
}

const ImportShowActions = (props : any) => {
  const {data} = useGetIdentity()
  return (
    <TopToolbar>
      {!['super_admin', 'admin'].includes(data?.role) ? null : <DeleteButton label={''}  record={props.data} mutationMode="pessimistic" redirect={false}/>}
    </TopToolbar>
  )
}

const ImportShow = (props: any) => {
  return (
    <Show {...props} emptyWhileLoading actions={<ImportShowActions/>}>
      <TabbedShowLayout>
        <Tab label="Данные импорта">
          <TextField source="id" label={'ID'}/>
          <ImportFileField />
          <SelectField source="status" label={'Статус'} choices={ImportStatusList}/>
          <TextField source="totalRows" label={'Всего'}/>
          <TextField source="importedRows" label={'Импортировано'}/>
          <TextField source="errorRows" label={'Ошибок'}/>
          <DateField source={'createdAt'} label={'Создан'} showTime/>
        </Tab>
        <Tab label="Ошибки импорта" path="errors">
          <ReferenceManyField reference="import-error" target="requestImportId" label={false} 
            sort={{field: 'id', order: 'DESC'}} pagination={<Pagination/>} perPage={50}>
              <Datagrid bulkActionButtons={false}>
                <TextField source="row" label={'Строка'}/>
                <TextField source="customId" label={'Внутренний ID'}/>
                <TextField source="error" label={'Ошибка'}/>
              </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
export default ImportShow
