import ListIcon from '@mui/icons-material/List'
import ProxyList from 'src/resources/proxy/ProxyList'
import ProxyEdit from 'src/resources/proxy/ProxyEdit'
import ProxyCreate from 'src/resources/proxy/ProxyCreate'

export default {
  create: ProxyCreate,
  edit: ProxyEdit,
  list: ProxyList,
  icon: ListIcon,
}