export enum ImportStatus {
  Created = 'created',
  InProgress = 'in_progress',
  Error = 'error',
  Finished = 'finished',
}

export const ImportStatusList = [
  {id: ImportStatus.Created, name: 'Создан'},
  {id: ImportStatus.InProgress, name: 'В процессе'},
  {id: ImportStatus.Error, name: 'Ошибка'},
  {id: ImportStatus.Finished, name: 'Завершен'},
]