import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    TextField,
    SelectField,
    Datagrid,
    DateField, TextInput, SelectInput, BooleanField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import EmptyList from 'src/components/list/EmptyList'
import { AdminRoleList} from 'src/types/enum/AdminRole'
import { LockOutline, LockOpenVariantOutline } from 'mdi-material-ui'
//import AdminRoleField from 'src/components/fields/adminRoleField'
//import {IfCanAccess} from 'src/components/permissions/IfCanAccess'

/*const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Admin'
    })
}*/

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <TextInput source="id" label={'ID'} variant={'outlined'}/>
            <TextInput source="name:$contL&login:$startsL" label={'Поиск...'}  variant={'outlined'} alwaysOn={true} resettable={true} />
            <SelectInput
                source="role"
                label={'Роль'}
                variant={'outlined'}
                choices={AdminRoleList}
            />
        </Filter>
    )
}

const AdminList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={false}
            filters={<_Filter/>}
            title="Пользователи"
            sort={{field: 'name', order: 'ASC'}}
            empty={<EmptyList title={'Нет пользователей'} description={'Вы можете добавить пользователя'}
                              buttonText={'Добавить пользователя'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <TextField source="login" label={'Логин'}/>
                <TextField source="name" label={'Имя'}/>
                <SelectField
                    source="role"
                    label={'Роль'}
                    choices={AdminRoleList}
                />
                <DateField source={'createdAt'} label={'Создан'} showTime={true}/>
                <BooleanField source="isDisable" label={'Доступ разрешен'} valueLabelTrue='Нет' valueLabelFalse='Да' TrueIcon={LockOutline} FalseIcon={LockOpenVariantOutline} looseValue={true}/>
            </Datagrid>
        </List>
    )
}

export default AdminList
