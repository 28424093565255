import * as React from 'react'
import {
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput, required,
} from 'react-admin'

const Form = (props: any) => {
  return (<>
    <TextInput source="proxy" label={'Адрес прокси'} fullWidth={true} validate={required()} variant={'outlined'}/>
    <SelectInput
      source="status"
      label={'Статус'}
      fullWidth={true}
      variant={'outlined'}
      choices={[
        { id: 'blocked', name: 'Заблокирован' },
        { id: 'active', name: 'Активен' }
      ]}
    />
    <NumberInput source="countTries" label={'Использование'} variant={'outlined'} fullWidth={true} />
  </>)
}
const ProxyForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{status: 'active', countTries: 0}}>
      <Form/>
    </SimpleForm>
  )
}
export default ProxyForm
